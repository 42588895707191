import { truncateText } from 'lib/helpers/functions/TextTruncate';
import { useTranslation } from 'next-i18next';

interface Props {
  error?: string;
  errorEffect?: string;
  value?: string;
  type?: string;
  placeholder?: string;
  Icon?: any | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  className?: string;
  inputType?: 'all' | 'char' | 'num';
  oldDesign?: boolean;
  style?: React.CSSProperties;
  inputMode?: 'text' | 'search' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;
}

const CustomInput = ({
  error,
  value,
  type = 'text',
  Icon,
  placeholder = '',
  onChange,
  onKeyUp,
  className = '',
  onFocus,
  onKeyDown,
  inputType = 'all',
  oldDesign = false,
  style = {},
  inputMode = 'text',
}: Props) => {
  const { t } = useTranslation('common');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      if (e.target.value) {
        if (inputType === 'all') onChange(e);
        else if (inputType === 'char') {
          const pattern = new RegExp('^[a-zA-Z\\s]+$');
          if (pattern.test(e.target.value)) onChange(e);
        } else if (inputType === 'num') {
          const pattern = new RegExp('[0-9]');
          if (pattern.test(e.target.value)) onChange(e);
        }
      } else {
        onChange(e);
      }
    }
  };

  if (oldDesign)
    return (
      <div className='relative w-full'>
        {Icon && (
          <span
            className={`${error ? 'mb-5' : ''} absolute inset-y-0 left-0 rtl:right-0 flex items-center pl-2 rtl:pr-2`}
          >
            <Icon color={error ? '#E81103' : '#757575'} />
          </span>
        )}
        <input
          className={`${error ? 'border border-apollo-error' : ''} ${
            Icon ? 'px-8' : 'px-2'
          } py-2 block w-full placeholder-light-gray outline-none h-10 bg-apollo-dark-white rounded text-[16px] font-bold text-dark-gray ${className}`}
          type={type}
          placeholder={t(placeholder) || ''}
          value={value}
          style={style}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          inputMode={inputMode}
        />
        {error && (
          <div className='block mt-1 mx-1 text-xs text-[10px] text-apollo-error text-left rtl:text-right'>
            {t(error)}
          </div>
        )}
      </div>
    );

  return (
    <div
      className={`h-[55px] bg-apollo-dark-white p-2 rounded rounded-b-none ${
        error ? 'border-2 border-b-apollo-error' : 'border-2 border-b-primary'
      }`}
    >
      <div className='relative w-full'>
        <div
          // className={`absolute rtl:left-[0px] ${
          //   Icon ? 'left-[32px] rtl:right-[32px]' : ''
          // } top-[-2px] text-[12px] px-2 ${error ? 'text-apollo-error' : 'text-primary'}`}
          className={`absolute ${
            Icon ? 'left-[32px] rtl:right-[32px]' : 'rtl:right-[0px]'
          } rtl:left-[0px] top-[-2px] w-full text-[12px] rtl:text-right px-2 ${
            error ? 'text-apollo-error' : 'text-primary'
          }`}
        >
          {truncateText(t(placeholder), 30)}
        </div>
        {Icon && (
          <span className={`absolute inset-y-0 flex items-center px-2`}>
            <Icon color={error ? '#E81103' : '#757575'} />
          </span>
        )}
        <input
          className={`${
            Icon ? 'px-10' : 'px-2'
          } pt-[12px] block w-full placeholder-light-gray outline-none h-10 bg-apollo-dark-white rounded text-[16px] font-bold text-dark-gray ${className}`}
          type={type}
          placeholder={t(placeholder) || ''}
          value={value}
          inputMode={inputMode}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
        {/* {error && (
        <div className='block mt-1 mx-1 text-xs text-[10px] text-apollo-error text-left rtl:text-right'>{t(error)}</div>
      )} */}
      </div>
    </div>
  );
};

export default CustomInput;
